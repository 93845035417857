
import React from "react"
import { Link } from "gatsby"

import ResponsiveImage from "components/responsiveImage"
import parseHtml from "helper/parseHtml"
import CategoryLabel from "components/categoryLabel"
import VideoDuration from "components/videoDuration"
import styleNameResolver from "helper/styleNameResolver"

import styles from "./styles.module.scss"
import colorStyles from "scss/color.module.scss"

export default class InlinePosts extends React.Component {

	render() {

		const {

			primary: {

				background_color,
				button_background_color,
				button_text,
				button_text_color,
				text_color,
				button_link

			},

			fields = []

		} = this.props;

		const items = [];

		fields.forEach(
			
			({ 
				article_to_link: {

					_meta: { uid },
					visibility,
					image,
					title,
					subtitle,
					primary_credit,
					category,
					background_color,
					video,
					video_duration

				}

			}) => {

				if(visibility === "visible")

					items.push(

						<Link key={uid} to={`/post/${uid}`} className={styles.item}>

							<div className={styles.image}>

								<ResponsiveImage src={image.url} />
								
								{ video && <VideoDuration background_color={background_color} video_duration={video_duration} /> }

							</div>

							<div className={styles.header}>

								<CategoryLabel enableLink={false} className={styles.category} category={category} backgroundColor={background_color} />

								<div className={styles.title}>

									<span>{parseHtml(title)}</span>

									<span className={styles.subtitle}>{parseHtml(subtitle)}</span>

								</div>

								{
									primary_credit &&

									<div className={styles.byline}>

										{primary_credit[0].text}

									</div>
								}

							</div>

						</Link>

					);

			}

		);

		return (

			<div className={`${styles.root} ${colorStyles[styleNameResolver(background_color, "background")]} ${colorStyles[styleNameResolver(text_color, "text")]}`}>

				<div className={`${styles.scroll}${items.length < 4 ? ` ${styles.flex}` : ""}`}>

					{items}

				</div>

				{

					Boolean((button_link || {})._meta) &&
				
					<Link to={`/${button_link._meta.uid}`} title={(button_text || [{}])[0].text} className={`${styles.button} ${colorStyles[styleNameResolver(button_background_color, "background")]} ${colorStyles[styleNameResolver(button_text_color, "text")]}`}>

						{parseHtml(button_text)}

					</Link>

				}

			</div>

		);

	}

}