
import React from "react"

import ResponsiveImage from "components/responsiveImage"
import styleNameResolver from "helper/styleNameResolver"

import styles from "./styles.module.scss"
import colorStyles from "scss/color.module.scss"

export default class Breather extends React.Component {

	render() {

		const {
			background_color,
			text_color,
			text,
			image
		
		} = this.props;

		return (

			<div className={`${styles.root} ${colorStyles[styleNameResolver(background_color, "background")]} ${colorStyles[styleNameResolver(text_color, "text")]}`}>

				{ image && <ResponsiveImage src={image.url} /> }

				{ text && <h3 className={styles.title}>{text[0].text}</h3> }

			</div>

		);

	}

}