
import React, { useEffect } from "react"
import { connect } from "react-redux"
import { bindActionCreators } from "redux"
import { graphql } from "gatsby"

import PageMetaData from "components/pageMetaData"
import PostHeader from "components/post/header"
import PostFeed from "components/postFeed"
import Breather from "components/breather"
import InlinePosts from "components/inlinePosts"

import { postHeader } from "graphQLFragments"

import { add as addPosts } from "state/post"
import { update as updateApp } from "state/app"

export const query = graphql`

	query HomeQuery($first: Int = 2 $after: String $postIds: [String!] = [""]) {

		prismic {

			homepage: allHomepages {

				edges {

					node {

						body {

							... on PRISMIC_HomepageBodyList_of_articles {

								primary {

									article {

										... on PRISMIC_Post_default {

											_meta { uid tags }

											visibility

											title

											subtitle
											
											date_published
											
											primary_credit
											
											secondary_credit
											
											category {
											
												... on PRISMIC_Category { 

													name
													header_background_color

												}
											
											}
											
											description
											
											image
											
											video
											
											video_duration
											
											background_color
											
											text_color
											
											layout

										}

									}

								}

							}

							... on PRISMIC_HomepageBodyBreather {

								primary {

									text
									text_color
									background_color
									image

								}

							}

							... on PRISMIC_HomepageBodyInline_list_of_articles {

								primary {

									button_text
									background_color
									text_color
									button_text_color
									button_background_color
									button_link {
                     
										... on PRISMIC_Category {

											_meta { uid }

										}
				                      
				                    }

								}

								fields {

									article_to_link {
										
										... on PRISMIC_Post_default {

											_meta { uid tags }

											visibility

											title

											subtitle
											
											date_published
											
											primary_credit
											
											secondary_credit
											
											category {
											
												... on PRISMIC_Category { 

													name
													header_background_color

												}
											
											}
											
											description
											
											image
											
											video
											
											video_duration
											
											background_color
											
											text_color
											
											layout

										}
									
									}

								}

							}

							__typename

						}

					}

				}

			}

			posts: allPost_defaults( first: $first, after: $after uid_in: $postIds sortBy: meta_firstPublicationDate_DESC where: { visibility: "visible" } ) {

				pageInfo {

			      hasNextPage

			      endCursor

			    }

				edges {

					node {

						...PostHeader

					}

				}

			}

		}

	}

`;

const trackedIds = [];

function trackItemsScrolledIntoView() {

	Array.from(document.querySelectorAll(".homepage-item, .feed-item"))

		.forEach(function(node) {

			const { y } = node.getBoundingClientRect();

			const {
				id,
				title
			
			} = node.dataset;

			if(y < 0 && trackedIds.indexOf(id) === -1) {

				trackedIds.push(id);

				if(window.dataLayer)

					window.dataLayer.push({ "event": "homepage-impressions", "title": title });

			}

		});

}


function HomeTemplate ({

	pageContext: {

		postIds = []

	},

	prismic,

	location,
	homepage,
	posts,
	pageInfo,
	loadingPosts,

	addPosts,
	updateApp

}) {

	const curatedPosts = homepage.edges[0].node.body;

	useEffect(() => {

		window.addEventListener("scroll", trackItemsScrolledIntoView);

		return () => window.removeEventListener("scroll", trackItemsScrolledIntoView);
	
	});

	return (

		<div>

			<PageMetaData url={location.href} />

			<div>

				{
					curatedPosts.map(

						(data, index) => {

								const key = `${data.__typename}_${index}`;

								switch(data.__typename) {

									case "HomepageBodyList_of_articles":
									case "PRISMIC_HomepageBodyList_of_articles":

										if(data.primary.article.visibility === "hidden")

											return null;

											return (

												<div 
													key={key} 
													className="homepage-item" 
													data-title={`Article ${index + 1}: ${(data.primary.article.title || [{}])[0].text}`} 
													data-id={key}

													onClick={() => {

														updateApp({ scrollToPostContent: true });

													}}

												>

													<PostHeader key={key} enableLink={true} {...data.primary.article} />

												</div>
											);

									case "HomepageBodyBreather":
									case "PRISMIC_HomepageBodyBreather":

										return (

											<div key={key} className="homepage-item" data-title={`Breather ${index + 1}: ${(data.primary.text || [{}])[0].text}`} data-id={key}>

												<Breather key={key} {...data.primary} />

											</div>

										);

									case "HomepageBodyInline_list_of_articles":
									case "PRISMIC_HomepageBodyInline_list_of_articles":

										return (

											<div key={key} className="homepage-item" data-title={`Inline Articles ${index + 1}`} data-id={key}>

												<InlinePosts {...data} />

											</div>

										);

									default:

										return null;
								}

						}

					).filter(v => v)

				}

				<PostFeed 

					feed={posts} 
					hasMore={pageInfo.hasNextPage}
					loading={loadingPosts}
					loadMore={() => {

						addPosts({

							query,
							pageInfo,
							prismic,
							feedId: "home",

							variables: {

								postIds

							}

						});

					}}

				/>

			</div>

		</div>

	);

}

HomeTemplate.fragments = [postHeader];

export default connect(

	(state, { data: { prismic: { homepage, posts: { edges, pageInfo } } } } ) => {

		const feed = state.feed.feeds["home"] || { items: [], pageInfo };

		return ({

			homepage,

			...feed,

			posts: [

				...edges,

				...feed.items.map(id => state.post.posts[id]).filter(v => v)

			],

			loadingPosts: state.app.loadingPosts

		});

	},

	dispatch => bindActionCreators({ addPosts, updateApp }, dispatch)

)(HomeTemplate);
